<template>
  <ThankYouPage />
</template>

<script>
import AbstractPage from 'chimera/all/components/page/AbstractPage'
import ThankYouPage from 'chimera/all/themes/blueflow/page/ThankYou/ThankYouPage'

export default {
  components: {
    ThankYouPage,
  },

  extends: AbstractPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Demande complétée',
      headDescription:
        "Comparez les entreprises de déménagement sur Comparateurdedemenagement.be. Des devis rapides, fiables et uniquement de votre région. Économisez jusqu'à 40 % des frais de déménagement.",
      path: '/quotes-requests/demande-completee',
    }
  },
}
</script>
